import { ReportingItem } from "@/store/kpi/modules/reporting/types/reportingItem";
import { isNil } from "lodash";
import { getIds } from "@/store/kpi/helpers/staffDepartmentPosition";
import { ApiEmployeePosition } from "@/api/kpi/types/apiEmployeePosition";

export interface ReportingEditableItem {
	userId: string;
	kpiId: string;
	position: ApiEmployeePosition;
	selectedDepartmentId: string;
	completion: string;
	justification: string;
	laborRate: string;
	laborRateJustification: string;
	ids: string;
}

export class ReportingEditableItemMapper {
	static map(source: ReportingItem): ReportingEditableItem {
		return {
			userId: source.userId,
			kpiId: source.kpiId,
			position: source.position,
			selectedDepartmentId: source.department.id,
			completion: !isNil(source.completion) ? String(source.completion) : "",
			justification: source.justification || "",
			laborRate: !isNil(source.laborRate?.value) ? String(source.laborRate.value) : "100",
			laborRateJustification: source.laborRate?.justification || "",
			ids: getIds(source.userId, source.department.id, source.position.id)
		}
	}	
}
